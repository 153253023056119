<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Employee Attendance</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                    <router-link :to="{name: 'employees'}">Employees</router-link>
                </li>
                 <li class="breadcrumb-item" v-if="employee">
                    <router-link :to="{name:'employee-details', params:{id:$route.params.employee_id}}"> {{employee.full_name}} [IES-{{$route.params.employee_id}}]</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Employee Attendance</li>
            </ol>
        </nav>
        <b-container fluid>
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            <div v-if="employee">
                <h4>Employee</h4>
                <b-card>
                    <b-card-text>
                        <span class="font-weight-bold">Name</span>
                        :
                        <span class="text-capitalize">{{employee.full_name}}</span>
                    </b-card-text>
                    <b-card-text>
                        <span class="font-weight-bold">Emp ID</span>
                        :
                        <span
                        >IES-{{employee.id}}</span>
                    </b-card-text>
                    <b-card-text>
                        <span class="font-weight-bold">Reporting To</span>
                        : {{employee.reporting_to.full_name}} [IES-{{employee.reporting_to.id}}]
                    </b-card-text>
                </b-card>
                <br />
                <br />
            </div>
            <b-row>
                <b-col lg="2" sm="12" class="mb-1rem">
                    <div class="form-group row">
                        <label
                            class="col-lg-3 col-md-2 col-sm-12 col-form-label text-nowrap"
                        >Year</label>
                        <div class="col-lg-9 col-md-10 col-sm-12">
                            <b-form-select
                                v-model="urlBody.year"
                                :options="years"
                                disabled-field="notEnabled"
                            ></b-form-select>
                        </div>
                    </div>
                </b-col>
                <!--<b-col lg="2" sm="12" class="mb-1rem">
                    <div class="form-group row">
                        <label
                            class="col-lg-3 col-md-2 col-sm-12 col-form-label text-nowrap"
                        >Month</label>
                        <div class="col-lg-9 col-md-10 col-sm-12">
                            <b-form-select
                                v-model="urlBody.month"
                                :options="months"
                                disabled-field="notEnabled"
                            ></b-form-select>
                        </div>
                    </div>
                </b-col>-->
                <b-col lg="1" sm="12" class="mb-1rem">
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <b-button variant="primary" @click="onFiltered">Filter</b-button>
                        </div>
                    </div>
                </b-col>
            </b-row>

            <b-table
                show-empty
                striped
                hover
                :items="items"
                :fields="fields"
                no-local-sorting
                @sort-changed="customSorting"
                @filtered="onFiltered"
                responsive
            >
                <template v-slot:cell(month)="row">
                    {{getMonthName(row.item.month)}}
                </template>
            </b-table>
        </b-container>
    </div>
</template>

<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
export default {
    components: {
        NotFound
    },
    data() {
        return {
            urlBody: {
                query: "",
                sort_by: "",
                order: "",
                employee_id: '',
                year: new Date().getFullYear(),
                month: ''
            },
            api_error: "",
            sortBy: "month",
            sortDesc: false,
            fields: [
                { key: "month", label: "Month", sortable: true},
                { key: "leave_days", label: "Leaves" },
                { key: "lop_days", label: "LOP" },
                { key: "night_shift_days", label: "Night Shifts" },
                { key: "working_days", label: "Working days" },
                //{ key: "actions", label: "Actions" }
            ],
            
            items: null,
            filter: null,
            access: false,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            employee: null,
            years: [
                { value: "", text: "All" },
            ],
            total_years:6,
            months: [
                { value: "", text: "All" },
                { value: "January", text: "January" },
                { value: "February", text: "February" },
                { value: "March", text: "March" },
                { value: "April", text: "April" },
                { value: "May", text: "May" },
                { value: "June", text: "June" },
                { value: "July", text: "July" },
                { value: "August", text: "August" },
                { value: "September", text: "September" },
                { value: "October", text: "October" },
                { value: "November", text: "November" },
                { value: "December", text: "December" }
            ],
            month_labels: {
                1:'January',
                2:'Febuary',
                3:'March',
                4:'April',
                5:'May',
                6:'June',
                7:'July',
                8:'August',
                9:'September',
                10:'October',
                11:'November',
                12:'December',
            },
        };
    },
    mounted() {
        this.access = this.hasPermission("CAN_MANAGE_EMPLOYEES");
        this.getYears();
        if (this.$route.query && this.$route.query.query) {
            this.filter = this.$route.query.query;
        }
        this.getEmployee();
        this.getEmployeeAttendance();
    },
    methods: {
        getYears() {
            const currentYear = (new Date()).getFullYear();
            for (let index = 0; index < this.total_years; index++) {
                this.years.push({
                    value: currentYear-index, text:currentYear-index
                })
            }
        },

        getMonthName(number){
            let month_number = parseInt(number) -1
            let mlist = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
            return mlist[month_number];
        },

        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },

        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },
        
        onFiltered() {
            this.urlBody.query = this.filter;
            let urlBody = this.deleteEmptyKeys(this.urlBody);
            this.api_error = "";
            this.$router
                .push({
                    path: this.$route.params[0],
                    query: urlBody
                }).catch(()=>{})
            this.getEmployeeAttendance();
        },

        getEmployeeAttendance: function() {
            this.startProgressBar();
            this.urlBody.employee_id = this.$route.params.employee_id;
            var urlBody = Object.keys(this.$route.query).length > 0 ? this.$route.query : this.deleteEmptyKeys(this.urlBody);
            if (this.$route.query && this.$route.query.query) {
                this.urlBody.query = this.$route.query.query;
            }
            var query = this.getAuthHeaders();
            query["params"] = urlBody;
            this.axios
                .get(this.$api.get_employee_attendance, query)
                .then(response => {
                    const result = response.data.data;
                    this.items = result;
                    this.api_error = "";
                    this.updateProgressBar(true);
                    this.$router.push({
                        path: this.$route.params[0],
                        query: this.deleteEmptyKeys(this.urlBody)
                    }).catch(()=>{});
                }).catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.items = [];
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },

        customSorting: function(ctx) {
            if (ctx.sortBy == "" || ctx.sortBy == null) {
                return;
            }
            this.urlBody.order = ctx.sortDesc ? "asc" : "desc";
            this.urlBody.sort_by = ctx.sortBy;
            let urlBody = this.deleteEmptyKeys(this.urlBody);
            this.api_error = "";
            this.$router
                .push({
                    path: this.$route.params[0],
                    query: urlBody
                }).catch(()=>{})
            this.getEmployeeAttendance();
        },

        getEmployee: function() {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            query["params"] = {};
            let data = {
                id: this.$route.params.employee_id
            }
            query["params"] = data;
            this.axios.get(this.$api.get_employees, query).then(response => {
                this.employee = response.data.data;
                this.updateProgressBar(true);
            })
            .catch(err => {
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.updateProgressBar(false);
                this.showAlert();
            });
        },
    }
};
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";
</style>